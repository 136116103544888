import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { pagesConfig } from '../../../config/config';
import { 
	DisplayContext,
	LangContext,
	ImageFormatter,
	Burger,
	BurgerMenu,
	useTranslation,
	LangSelect,
	BookingButton,
	Select,
	useDevice,
	useIsHomePage
} from '../../index';

const logo = require('../../../images/brand/yobale-transport-aeroport-logo.jpg');
const logoWebp = require('../../../images/brand/yobale-transport-aeroport-logo.webp');

const Header = () => {
	const {
		goTo,
		goToTop,
		isBurgerMenuOpen,
		toggleBurgerMenu,
		toggleContactForm,
		closePricingPopUp,
		closeContactForm,
		handleReduceForm,
		handleBookingFormFocusAlways,
	} = useContext(DisplayContext)
	const { lang } = useContext(LangContext)
	const { desktop } = useDevice();
	const navigate = useNavigate();
	const headerSection = useTranslation("headerSection")
	const isHomePage = useIsHomePage();

	const servicesValues = [headerSection?.menuService1, headerSection?.menuService2, headerSection?.menuService3];
	const servicesPossibleValues = [
		{to: pagesConfig[`${lang.toUpperCase()}_SERVICE_AIRPORTS`]}, 
		{to: pagesConfig[`${lang.toUpperCase()}_SERVICE_CITY`]}, 
		{to: pagesConfig[`${lang.toUpperCase()}_SERVICE_LONG`]}
	];
	const entrepriseValues = [headerSection?.menu31, headerSection?.menu32, headerSection?.menu33];
	const entreprisePossibleValues = ["goToConcept", "goToVehicules", "goToTeam"];

	const handleHeaderClick = () => {
		handleReduceForm();
		if (!isHomePage) {
			navigate(pagesConfig[`FR_HOME`]);
		}
	}

	const handleMenuSelect = (e) => {
		closeContactForm()
		closePricingPopUp()
		if (isHomePage) {
			goTo(e)
		} else {
			navigate(pagesConfig[`FR_HOME`])
			setTimeout(() => {
				goTo(e)
			}, 500)
		}
	}

	const handleLinkSelect = (e) => {
		const direction = e?.to || undefined;
		if (direction) {
			closeContactForm()
			closePricingPopUp()
			navigate(direction);
			goToTop();
		} else {
			return 
		}
	}

	return (
		<header className="Header">
			<div className="SectionContained">

				<div className="Navbar">
					<div className="HeaderLeftContainer" onClick={handleHeaderClick} >
						<ImageFormatter className="HeaderLogo" webpImage={logoWebp} image={logo} alt="yobale-transport-aeroport-logo" />
					</div>
					{desktop ? 
						<div className="HeaderRightContainer">
							<Select
								customTriggerClass={"HeaderRightButton"}
								customSelectClass={"SelectItem SelectItemGreen"}
								value={headerSection?.menu1}
								onValueChange={e => handleLinkSelect(e)} 
								possibleValues={servicesPossibleValues}
								displayedValues={servicesValues}
								ariaLabel={"company-section"}
								fixedTitle={headerSection?.menu1}
							/>
							<div className="HeaderRightButton" onClick={() => {handleMenuSelect("goToRoutes")}}>{headerSection?.menu2}</div>
							<Select 
								customTriggerClass={"HeaderRightButton"}
								customSelectClass={"SelectItem SelectItemGreen"}
								value={headerSection?.menu3}
								onValueChange={e => handleMenuSelect(e)} 
								possibleValues={entreprisePossibleValues}
								displayedValues={entrepriseValues}
								ariaLabel={"company-section"}
								fixedTitle={headerSection?.menu3}
							/>
							<div className="HeaderRightButton" onClick={() => {handleMenuSelect("goToFaq")}}>{headerSection?.menu6}</div>
							<div className="HeaderRightButton" 
								onClick={() => {
									closePricingPopUp()
									toggleContactForm()
								}}
							>{headerSection?.menu8}</div>
							<BookingButton fontSize={"16px"}/>
							<LangSelect />
						</div>
						:
						<>
							<Burger
								isBurgerMenuOpen={isBurgerMenuOpen}
								toggleBurgerMenu={toggleBurgerMenu}
								/>
							<BurgerMenu 
								isBurgerMenuOpen={isBurgerMenuOpen}
								toggleBurgerMenu={toggleBurgerMenu}
								handleBookingFormFocusAlways={handleBookingFormFocusAlways}
								toggleContactForm={toggleContactForm}
							/>
						</>
					}
				</div>
			</div>
		</header>
	)
}

export default Header
import React, { useContext } from 'react';
import {
  ContactButton,
  DisplayContext, 
  Faq, 
  ImageFormatter,
  useTranslation,
  Vehicules
} from '../../index';

const ServicePage = (props) => {
  const {handleLoseFocus} = useContext(DisplayContext);
  const pageServiceText = useTranslation(props?.pageText);
  const destinationsList = useTranslation("destinationsList");
  const hotelList = useTranslation("hotelList");
  const isSingleList = pageServiceText?.origine === "";

  return (
    <main className="Main">
      <div className="Section" onClick={handleLoseFocus}>
        <div className="SectionContained">
          <h2 className="SectionTitle">
            {pageServiceText?.title}
          </h2>
        {/* </div>
      </div>
      <div className="Presentation"></div>
      <div className="Section" onClick={handleLoseFocus}>
        <div className="SectionContained"> */}
          <br></br>
          <br></br>
          <div className="TeamContainer">
            <div className="TeamContainerRight">
              <ImageFormatter 
                className="TeamImage" 
                webpImage={props?.image1Webp} 
                image={props?.image1} 
                alt="vtc-hybrid-senegal" 
                width={400}
                height={434}
              />
            </div>
            <div className="TeamContainerLeft">
              <div className="Paragraph">
                {<>
                    <span className='pagesParagraphTitle'>{pageServiceText?.paragraphs1?.[0]}</span>{pageServiceText?.paragraphs1?.[1]}
                    <br></br>
                    <br></br>    
                </>}
                {<>
                    <span className='pagesParagraphTitle'>{pageServiceText?.paragraphs2?.[0]}</span>{pageServiceText?.paragraphs2?.[1]}
                    <br></br>
                    <br></br>    
                </>}
              </div>
            </div>
          </div>
          
          <div className="TeamContainer TeamContainerFirst">
            <div className="TeamContainerRight">
              <div className="Paragraph">
                {<>
                    <span className='pagesParagraphTitle'>{pageServiceText?.paragraphs3?.[0]}</span>{pageServiceText?.paragraphs3?.[1]}
                    <br></br>
                    <br></br>    
                </>}
                {<>
                    <span className='pagesParagraphTitle'>{pageServiceText?.paragraphs4?.[0]}</span>{pageServiceText?.paragraphs4?.[1]}
                    <br></br>
                    <br></br>    
                </>}
              </div>
            </div>
            <div className="TeamContainerLeft">
              <ImageFormatter 
                className="TeamImage" 
                webpImage={props?.image2Webp} 
                image={props?.image2} 
                alt="taxi-aéroport-dakar" 
                width={400}
                height={450}
              />
            </div>
          </div>

        {props?.vh && <Vehicules />}

          <div className="RouteCTA">
            <div className="RouteCTATextContainer">
              <p className="RouteCTAText1">
                {pageServiceText?.contact1}
              </p>
              <p className="RouteCTAText2">
                {pageServiceText?.contact2}
              </p>
            </div>
            <ContactButton />
          </div>

          <div>
            <h3 className="SectionSubTitle my-0">
              {pageServiceText?.listTitle}
            </h3>
            <ul>
              {destinationsList?.map((d, index) => {
                return (
                  <li key={index} className="RouteCTAText2" style={{width:"fit-content", listStyleType: "none"}}>
                    {isSingleList ?
                    `${d}`
                    :
                    `${pageServiceText?.origine} ${pageServiceText?.to} ${d} <> ${d} ${pageServiceText?.to} ${pageServiceText?.origine}`
                    }
                  </li>
                )
              })}
            </ul>
          </div>

          {props?.hotelList && 
            <div>
              <h3 className="SectionSubTitle my-0">
                {pageServiceText?.hotelListTitle}
              </h3>
              <ul>
                {hotelList?.map((d, index) => {
                  return (
                    <li key={index} className="RouteCTAText2" style={{width:"fit-content", listStyleType: "none"}}>
                      {d}
                    </li>
                  )
                })}
              </ul>
            </div>
          }

        </div>
        <Faq />
      </div>
    </main>
  )
}
export default ServicePage;
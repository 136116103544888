import { useContext, useEffect } from "react";
import Rating from "react-rating";
import { LangContext } from "../../Context/LangContext";
import { ratingsConfig } from "../../../config/config";
import { DisplayContext, ImageFormatter, useTranslation } from "../../index";

import tripAdvisorLogo from "../../../images/reviews/Tripadvisor_logo_300.png";
import tripAdvisorLogoWebp from "../../../images/reviews/Tripadvisor_logo_300.webp";

const TripAdvisorReview = () => {
  const reviewsText = useTranslation("reviewsText")
  const externalLinks = useTranslation("externalLinks")
  const { lang } = useContext(LangContext)
  const { taTotalReviews, taRating } = useContext(DisplayContext);

  const isRatingDynamic = ratingsConfig.DYNAMIC_RATINGS;
  const rating = taRating || ratingsConfig.TRIP_ADVISOR_DEFAULT_RATING;

  useEffect(() => {
    if (!isRatingDynamic) return;
    async function loadTripAdvisor() {
      if (window && document) {
        const script = document.createElement("script");
        script.src = `https://www.jscache.com/wejs?wtype=cdsratingsonlynarrow&uniq=121&locationId=25439304&lang=${lang}&border=false&shadow=false&display_version=2`
        script.async = true;
        document.body.appendChild(script);
      }
    }

    try {
      loadTripAdvisor();
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <a 
      className="RatingContainer TripAdvisorContainer"
      target="_blank"
      rel="noreferrer"
      href={externalLinks?.tripAdvisor}
    >
      <div className="TripAdvisorTitle">{reviewsText.recommended}</div>
      <ImageFormatter 
        className="GoogleReviewLogo TAReviewLogo"
        webpImage={tripAdvisorLogoWebp}
        image={tripAdvisorLogo}
        alt="Trip Advisor Review" 
        width={"200"}
        height={"43"}
      />  
      {isRatingDynamic ? (
        <>
          <div id="TA_cdsratingsonlynarrow121" className="TA_cdsratingsonlynarrow">
            <ul id="GAkhbAots" className="TA_links Ev3swPmMxYdv">
            </ul>
          </div>
        </>
      ) : (
        <div className="TripAdvisorRatingContainer">
          <Rating
            className="TripAdvisorRating"
            placeholderRating={rating}
            emptySymbol={<div className="grey-circle"></div>}
            placeholderSymbol={<div className="green-circle"></div>}
            fullSymbol={<div className="green-circle"></div>}
            readonly
          />
          <div className="TripAdvisorRatingText">
            {taTotalReviews}{reviewsText.reviewsWord}
          </div>
        </div>
      )}
      <div className="GoogleReviewRead">
        {reviewsText.readOurReviews}
      </div>
    </a>
  )
}
export default TripAdvisorReview;
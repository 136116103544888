export const langConfig = {
  DEFAULT_LANG: 'fr',
  AVAILABLE_LANGS: ['fr', 'en', 'es', 'br'],
  LANG_SELECTOR_DISPLAY: ['Français', 'English', 'Español', 'Português'],
  LANGS_URLS: {
    fr: '/',
    en: '/en/',
    es: '/es/',
    br: '/br/',
  },
};

export const helmetPages = {
  HOME: 'home',
  AIRPORT: 'airport',
  CITY: 'city',
  LONG: 'long'
}

export const pagesConfig = {
  FR_HOME: '/',
  FR_SERVICE_AIRPORTS: '/transferts-aeroports',
  FR_SERVICE_CITY: '/transferts-urbains',
  FR_SERVICE_LONG: '/location-longue-duree',
  EN_HOME: '/',
  EN_SERVICE_AIRPORTS: '/airports-transfers',
  EN_SERVICE_CITY: '/city-to-city-rides',
  EN_SERVICE_LONG: '/long-term-rental',
  ES_HOME: '/',
  ES_SERVICE_AIRPORTS: '/traslados-aeropuerto',
  ES_SERVICE_CITY: '/traslados-interurbanos',
  ES_SERVICE_LONG: '/alquiler-largo-plazo',
  BR_HOME: '/',
  BR_SERVICE_AIRPORTS: '/transfers-aeroporto',
  BR_SERVICE_CITY: '/viagens-entre-cidades',
  BR_SERVICE_LONG: '/aluguel-longa-duraçao',
};

export const emailConfig = {
  EMAIL_SERVICE_ID: 'netim',
  EMAIL_TEMPLATE_BOOKING_ID: 'yobale_reservation_admin',
  EMAIL_TEMPLATE_CONTACT_ID: 'yobale_formulaire',
  EMAIL_SERVICE_KEY: 'user_2AdGSeLLpGo6uxxkJoBB9',
};

export const whatsappConfig = {
  WHATSAPP_COMPANY_NUMBER: '221786330808',
  WHATSAPP_URL: 'https://api.whatsapp.com/send?phone=',
};


export const googleConfig = {
  GOOGLE_PLACE_ID: 'ChIJk449GE31-kwRcJDM8GK48bg',
  GOOGLE_PLACES_API_KEY: 'AIzaSyB3aYKVSL9R3Bw3ojVzLqcClSxoc6ombE8',
  GOOGLE_WRITE_REVIEW_URL:
    'https://search.google.com/local/writereview?placeid=',
  GOOGLE_READ_REVIEWS_URL: 'https://search.google.com/local/reviews?placeid=',
  GOOGLE_RATING_DECIMALS: 1,
};

export const tripAdvisorConfig = {
  TRIP_ADVISOR_PRODUCT_URL:
    'https://www.tripadvisor.com/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_READ_REVIEWS_FR_URL:
    'https://www.tripadvisor.fr/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_READ_REVIEWS_EN_URL:
    'https://www.tripadvisor.com/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_READ_REVIEWS_ES_URL:
    'https://www.tripadvisor.es/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_GOOD_REVIEW_FR_URL:
    'https://www.tripadvisor.fr/ShowUserReviews-g811262-d25439304-r914114228-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_GOOD_REVIEW_EN_URL:
    'https://www.tripadvisor.com/ShowUserReviews-g811262-d25439304-r914114228-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_GOOD_REVIEW_ES_URL:
    'https://www.tripadvisor.es/ShowUserReviews-g811262-d25439304-r914114228-Yobale-Rufisque_Dakar_Region.html',
};

export const ratingsConfig = {
  PUBLIC_RATINGS_URL:
    'https://raw.githubusercontent.com/raphaelpg/yobale-public/main/reviews/reviews.json',
  DYNAMIC_RATINGS: false,
  GOOGLE_DEFAULT_RATING: 4.8,
  GOOGLE_DEFAULT_REVIEWS_NUMBER: 17,
  TRIP_ADVISOR_DEFAULT_RATING: 5,
  TRIP_ADVISOR_DEFAULT_REVIEWS_NUMBER: 20,
};

export const reviewsConfig = {
  textLength: 180,
};

export const carouselConfig = {
  dots: false,
  arrows: false,
  infinite: true,
  pauseOnHover: false,
  // autoplay: true,
  autoplay: false,
  autoplaySpeed: 10000,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '10%',
  touchThreshold: 100,
  swipeToSlide: true,
  accessibility: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '0px',
      },
    },
  ],
};

export const delaysConfig = {
  BACK_TO_HOMEPAGE_BOOKING_FORM_DELAY: 500,
} 
import React, { useContext } from 'react';
import {
  ContactButton,
  DisplayContext,
  ImageFormatter,
  useTranslation,
} from '../../index';

const phoneLogo = require('../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.png');
const phoneLogoWebp = require('../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.webp');
const enveloppeLogo = require('../../../images/icons/envelope.png');
const enveloppeLogoWebp = require('../../../images/icons/envelope.webp');
const taLogo = require('../../../images/brand/ta_40.png');
const taLogoWebp = require('../../../images/brand/ta_40.webp');
const vLogo = require('../../../images/brand/v_40.png');
const vLogoWebp = require('../../../images/brand/v_40.webp');
const gLogo = require('../../../images/brand/google_g_40.png');
const gLogoWebp = require('../../../images/brand/google_g_40.webp');
const liLogo = require('../../../images/brand/li_40.png');
const liLogoWebp = require('../../../images/brand/li_40.webp');

const Footer = () => {
  const { handleLoseFocus } = useContext(DisplayContext);
  const footerText = useTranslation('footer');
  const externalLinks = useTranslation('externalLinks');

  return (
    <footer className="Footer" onClick={handleLoseFocus}>
      <div className="SectionContained">
        <div className="FooterContainer">
          <div className="FooterColumn1">
            <div className="FooterTitle">{footerText.title1}</div>
            <div className="FooterSubContainer">
              <div className="FooterDescription">{footerText.c11}</div>
              <div className="FooterDescription">{footerText.c12}</div>
            </div>
            <div className="FooterSubContainer">
              <div className="FooterSubtitle">{footerText.c13}</div>
              <div className="FooterDescription">{footerText.c14}</div>
              <div className="FooterDescription">{footerText.c15}</div>
              <div className="FooterDescription">{footerText.c16}</div>
              <div className="FooterDescription">{footerText.c17}</div>
              <div className="FooterDescription">{footerText.c18}</div>
            </div>
          </div>
          <div className="FooterColumn1">
            <div className="FooterTitle">{footerText.title2}</div>
            <div className="FooterSubContainer">
              <div className="FooterDescription">{footerText.c21}</div>
              <div className="FooterDescriptionWithLogo">
                <ImageFormatter
                  className={'phoneLogo'}
                  webpImage={phoneLogoWebp}
                  image={phoneLogo}
                  alt={'reserver-VTC-transfert-aeroport-DSS-Dakar-Saly'}
                  width={'40'}
                  height={'40'}
                />
                {footerText.c22}
              </div>
            </div>
            <div className="FooterSubContainer">
              <div className="FooterDescription">{footerText.c23}</div>
              <a
                className="FooterContactButton"
                href="mailto:contact@yobale.sn"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ImageFormatter
                  className={'enveloppeLogo'}
                  webpImage={enveloppeLogoWebp}
                  image={enveloppeLogo}
                  alt={'yobale-contact-Dakar-Saly'}
                  width={'40'}
                  height={'40'}
                />
                {footerText.c24}
              </a>
            </div>
            <div className="FooterSubContainer">
              <div className="FooterDescription">{footerText.c25}</div>
              <ContactButton />
            </div>
          </div>
          <div className="FooterColumn1">
            <div className="FooterTitle">{footerText.title3}</div>
            <div className="FooterSubContainer">
              <div className="FooterDescription">{footerText.c31}</div>
            </div>
            <div className="FooterSubContainer">
              <a
                className="FooterDescriptionWithLogo footerBrandContainer"
                href={externalLinks?.tripAdvisor}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageFormatter
                  className={'phoneLogo brandLogo'}
                  webpImage={taLogoWebp}
                  image={taLogo}
                  alt={'trip-advisor-logo'}
                  width={'40'}
                  height={'40'}
                />
                {footerText.c32}
              </a>
              <a
                className="FooterDescriptionWithLogo footerBrandContainer"
                href={externalLinks?.viator}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageFormatter
                  className={'phoneLogo brandLogo'}
                  webpImage={vLogoWebp}
                  image={vLogo}
                  alt={'viator-logo'}
                  width={'40'}
                  height={'40'}
                />
                {footerText.c33}
              </a>
              <a
                className="FooterDescriptionWithLogo footerBrandContainer"
                href={externalLinks?.google}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageFormatter
                  className={'phoneLogo brandLogo'}
                  webpImage={gLogoWebp}
                  image={gLogo}
                  alt={'google-g-logo'}
                  width={'40'}
                  height={'40'}
                />
                {footerText.c34}
              </a>
              <a
                className="FooterDescriptionWithLogo footerBrandContainer"
                href={externalLinks?.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageFormatter
                  className={'phoneLogo brandLogo'}
                  webpImage={liLogoWebp}
                  image={liLogo}
                  alt={'linkedin-logo'}
                  width={'40'}
                  height={'40'}
                />
                {footerText.c35}
              </a>
            </div>
          </div>
        </div>
        <div className="FooterBottom">
          <div className="FooterColumn1"></div>
          <div className="FooterColumn1">
            <div className="FooterLastPhrase">{footerText.copyright}</div>
          </div>
          <div className="FooterColumn1"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
